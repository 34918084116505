@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

:root {
  --coral-pink: #ff9085;
  --dusty-yellow: #fed792;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-projectLink {
  background-color: grey;
  color: white;
  text-decoration: none;
  border-radius: 32px;
}

.btn-projectLink:hover {
  background-color: lightgray;
  text-decoration: none;
}

.blackout {
  font-family: 'Blackout';
}

.content {
  word-break: break-word;
}

.blogCard:hover {
  background-color: #2a6496;
}
