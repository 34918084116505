@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

:root{
  --coral-pink: #ff9085;
  --dusty-yellow: #fed792;
}


.main{
  color: var(--coral-pink);
}

.highlight{
  color: var(--dusty-yellow);
  margin:0;
}

.intro-card{
  background: var(--coral-pink);
}

.gradient-transparent {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(227,82,204,1) 0%, rgba(253,196,15,0.3) 100%);
}

.gradient-opaque {
  background: rgba(227,82,204,1);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(227,82,204,1) 0%, rgba(253,196,15,1) 100%);
}

.card-comments img {
  width: 4rem; }

.card-btn {
  margin-bottom: 0.3rem;
}

#profile-image-div {
  padding:20px
}

#profile-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}